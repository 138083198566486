<template>
  <div class="table-list">
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col   cols="6" >
            <b-button
            variant="outline-primary"
            @click="selectAllRows"
          >
            Select all
          </b-button>
          </b-col>
          <!-- Per Page -->
          <b-col
          v-if="$can('delete', 'price')" 
          cols="6"   class="d-flex align-items-end justify-content-end mb-1">
          <b-button variant="primary" class="mr-1" @click="DeleteRows">
            Delete Selected Prices
          </b-button>
        </b-col>
        </b-row>
        <b-row class="mb-1 mt-1">
          <b-col cols="3">     <b-form-input
            data-testid="course-price-search" 
            v-model="searchQueryP"
            class="d-inline-block mr-1"
            placeholder="Search Price..." /></b-col>
           
            <b-col cols="3">   
                <b-form-input
                    
              v-model="searchQueryC"
              class="d-inline-block mr-1"
              placeholder="Search Category..." /></b-col>
              <b-col cols="3">     <b-form-input
        
                v-model="searchQueryCITY"
                class="d-inline-block mr-1"
                placeholder="Search City..." /></b-col>
              <b-col cols="3">     <b-form-input
                    
                v-model="searchQueryCOURSE"
                class="d-inline-block mr-1 "
                placeholder="Search Course..." /></b-col>
                <b-col cols="3">   
                  <v-select
                  class=" mt-1"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortOptions"
                v-model="searchQueryTYPE"  
                placeholder="Search Type..."
                
                label="label"
                :reduce="(val)=> val.id"
                :clearable="true"
                 />
                
                </b-col>
                  <b-col cols="3">     <b-form-input
                    type="number"
                    v-model="searchQueryDURATION"
                    class="d-inline-block mr-1 mt-1"
                    placeholder="Search Duration..." /></b-col>
                  </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            ">
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
              <!-- <label>SortBy</label>
              <v-select
              style="width:200px"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortOptions"
                v-model="sort"  
                   multiple
                
                label="label"
                :reduce="(val)=> val.id"
                :clearable="true"
                 /> -->
          </b-col>

          <!-- Search -->
        
       
        </b-row>
      </div>

      <b-table
      data-testid="course-price-table"
        ref="refPriceListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="allItem"
        :sort-by.sync="sort"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
        show-empty
    
        empty-text="Loading...">
        <!-- Column: Status -->
        <template #cell(online)="data">
          <span v-if="data.item.online == 0">Classic</span>
          <span v-else>Online</span>
        </template>
        <template #cell(price)="data">
          {{ data.item.price }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="0.5em"
            height="0.5em"
            fill="currentColor"
            class="bi bi-currency-pound"
            viewBox="0 0 16 16">
            <path
              d="M4 8.585h1.969c.115.465.186.939.186 1.43 0 1.385-.736 2.496-2.075 2.771V14H12v-1.24H6.492v-.129c.825-.525 1.135-1.446 1.135-2.694 0-.465-.07-.913-.168-1.352h3.29v-.972H7.22c-.186-.723-.372-1.455-.372-2.247 0-1.274 1.047-2.066 2.58-2.066a5.32 5.32 0 0 1 2.103.465V2.456A5.629 5.629 0 0 0 9.348 2C6.865 2 5.322 3.291 5.322 5.366c0 .775.195 1.515.399 2.247H4v.972z" />
          </svg>
        </template>

        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <a>
            <router-link
              :to="`/update-price/${data.item.id}`"
              style="margin: 0px 12px">
              <feather-icon icon="EditIcon" data-testid="edit-course-price"/>
            </router-link>
          </a>
          <a  v-if="$can('delete', 'price')" role="button" @click="DeletePrice(data.item.id)">
            <feather-icon icon="TrashIcon" data-testid="delete-course-price" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItem"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, computed, watch, reactive } from "@vue/composition-api";
import Vue from "vue";
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,
    vSelect,
  },
  setup() {
    const PricesList = ref([]);
    const meta = reactive({});
    const perPageOptions = [100,200];
    const perPage = ref(200);
    const refPriceListTable = ref(null);
    const totalItem = ref(0);
    const searchQuery = ref("");
    const searchQueryP = ref("");
    const searchQueryC = ref("");
    const searchQueryCOURSE = ref("");
    const searchQueryCITY = ref("");
    const searchQueryTYPE = ref("");
    const searchQueryDURATION = ref("");
    const currentPage = ref(1);
    const allItem = ref([]);
    const sortOptions = [{id:0,label:'Classic'},{id:1,label:'Online'},
    // {id:'cities.name',label:'City'}
    // ,{id:'price',label:'Price'}
        
        
        ];
    const sort=ref('')
    const selectedRow = ref([]);
    const onRowSelected = (items) => {
      selectedRow.value = items;
    
    };
    const DeleteRows = () => {
 
      let data = [];
      selectedRow.value.forEach((el) => {
        data.push(el.id);
      });
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
       
          store
            .dispatch("price/DeleteSelected", data)
            .then((response) => {
              GetAllPrices();
              Vue.swal({
                title: "Prices Deleted ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };
    const refetchData = () => {
      refPriceListTable.value.refresh();
    };
    const SortArray=[]
    watch([currentPage, perPage,  searchQueryP,
      searchQueryC,
      searchQueryCOURSE,
      searchQueryDURATION,
      searchQueryTYPE,
      searchQueryCITY,sort], () => {
  
      GetAllPrices();
    });
    const GetAllPrices = (ctx, callback) => {
      let data=null
   
   if( !SortArray.includes(sort.value) && sort.value!='' ){
   
     SortArray.push(sort.value)
     data=SortArray.toString().replace('[','')
     }
    
      store
        .dispatch("price/getAllPrice", {
          "filter[price]": searchQueryP.value,
          'filter[category]':searchQueryC.value,
        'filter[city]':searchQueryCITY.value,
        'filter[course]':searchQueryCOURSE.value,
        'filter[type]':searchQueryTYPE.value,
        'filter[duration]':searchQueryDURATION.value,
          sort:data,

          per_page: perPage.value,
          page: currentPage.value,
        })
        .then((response) => {
          
          // PricesList.value=response.data.data
          allItem.value = response.data.data;
          totalItem.value = response.data.meta.total;
        

          // callback(data);
        });
    };
    GetAllPrices();
    const dataMeta = computed(() => {
      const localItemsCount = refPriceListTable.value
        ? refPriceListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItem.value,
      };
    });
    // GetAllPrices()
    const DeletePrice = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("price/DeletePrice", id).then((response) => {
            refetchData();
            Vue.swal({
              title: "Price Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };
    const tableColumns = [
      { key: "id", lanel: "ID", sortable: true },
      { key: "course.name", label: "Course Name", sortable: true },
      { label: "City", key: "city.name", sortable: true },
      {
        key: "course.category.type",
        label: "Category",

        sortable: true,
      },
      { key: "price", label: "Price", sortable: true },
      { key: "course.duration", label: "Duration", sortable: true },
      { key: "online", label: "Type", sortable: true },
      { key: "actions" },
    ];

    const selectAllRows=()=> {
      
      if(!selectedRow.value.length>0){
      
      refPriceListTable.value.selectAllRows()
      }
      else{
        refPriceListTable.value.clearSelected()
      }
    }
    return {
      tableColumns,
      PricesList,
      DeletePrice,
      meta,
      searchQueryP,
      searchQueryC,
      searchQueryCOURSE,
      searchQueryCITY,
      searchQueryDURATION,
      searchQueryTYPE,
      perPageOptions,
      totalItem,
      currentPage,
      perPage,
      dataMeta,
      refPriceListTable,
      refetchData,
      GetAllPrices,
      searchQuery,
      allItem,
      onRowSelected,
      selectedRow,
      DeleteRows,
      sortOptions,
      sort,
      selectAllRows,
      SortArray,
     
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
svg.bi.bi-currency-pound {
  height: 14px;
  width: 16px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
